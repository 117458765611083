import React, { useState } from 'react';
import { DatePicker } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { useTranslations } from 'next-intl';

interface CustomDatePickerProps {
  value?: string | null;
  // onChange?: (value: string | null) => void;
  // onChange?: React.Dispatch<React.SetStateAction<string | null>>;
  onChange?: (value: string) => void;
  textClassName?: string;
  disabled?: boolean;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  value,
  onChange,
  textClassName = '',
  disabled = false,
}) => {
  const t = useTranslations('PersonalInfoSubmit');

  const [open, setOpen] = useState<boolean>(false);
  // const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);

  const displayValue = value ? dayjs(value) : null;
  // const displayValue = dayjs('31-12-2028');
  // const displayValue = dayjs('02.11.2032');
  // const displayValue = dayjs('2032.11.02');

  const handleOpenChange = (open: boolean): void => {
    setOpen(open);
  };

  const handleDateChange = (date: Dayjs | null): void => {
    setOpen(false);
    if (onChange) {
      onChange(date ? date.format('YYYY-MM-DD') : '');
    }
  };

  return (
    <div className={`relative inline-block ${disabled ? 'cursor-not-allowed' : ''}`}>
      <div
        onClick={() => setOpen(!open)}
        className={`flex h-8 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} items-center rounded-md border-0 border-gray-300 px-0 py-1 transition-colors duration-300 hover:border-blue-500`}
      >
        <span className={`${textClassName} ${displayValue ? '' : 'text-[#80878E] opacity-60'}`}>
          {displayValue ? displayValue.format('DD/MM/YYYY') : t('selectdate')}
        </span>
      </div>
      <DatePicker
        // disabled={disabled}
        // open={disabled ? !disabled : open}
        minDate={dayjs()}
        open={open}
        onOpenChange={handleOpenChange}
        onChange={handleDateChange}
        value={displayValue}
        className="absolute left-0 top-0 h-0 w-0 opacity-0"
      />
    </div>
  );
};

export default CustomDatePicker;
