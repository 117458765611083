import { useState } from 'react';
import Image from 'next/image';
import { postUserPublicFileUpload } from '@/lib/services/gonggongjiekou';
import NewLoading from '../NewLoading';

const UploadImgBtn = ({ onChange, from }: { from?: string; value?: string; onChange?: (value: string) => void }) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div
      className="flex size-20 cursor-pointer flex-row items-center justify-center rounded bg-white"
      onClick={() => {
        if (loading) {
          return;
        }
        document?.getElementById('commentInput')?.click();
      }}
    >
      {from === 'returnOrder' ? (
        <input
          type="file"
          id="commentInput"
          className="hidden"
          accept="image/jpeg, image/png"
          onChange={(e) => {
            if (loading) {
              return;
            }

            const files = e.target.files;
            if (files && files[0]) {
              const file = files[0];

              // Check file type
              if (!['image/jpeg', 'image/png'].includes(file.type)) {
                return;
              }

              // Check file size (5MB = 5 * 1024 * 1024 bytes)
              if (file.size > 5 * 1024 * 1024) {
                return;
              }

              setLoading(true);
              postUserPublicFileUpload({}, file)
                .then((response) => {
                  setLoading(false);
                  if (response?.code === 0) {
                    if (onChange) {
                      onChange(response?.data);
                    }
                  }
                })
                .catch(() => {
                  setLoading(false);
                });
            }
          }}
        />
      ) : (
        <input
          type="file"
          id="commentInput"
          className="hidden"
          accept="image/*"
          onChange={(e) => {
            if (loading) {
              return;
            }
            const files = e.target.files;
            if (files) {
              setLoading(true);
              postUserPublicFileUpload({}, files[0]).then((response) => {
                setLoading(false);
                if (response?.code === 0) {
                  if (onChange) {
                    onChange(response?.data);
                  }
                }
              });
            }
          }}
        />
      )}

      {loading ? (
        <NewLoading />
      ) : (
        <Image src={'/images/product/addIcon.svg'} width={24} height={24} className={'size-6'} alt="addIconImage" />
      )}
    </div>
  );
};

export default UploadImgBtn;
