const IconDeletePopup = ({
  width = '86',
  height = '78',
  // color = '#4DBAC0',
}: {
  width?: string;
  height?: string;
  color?: string;
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 86 78" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.9999 20C44.6568 20 45.9999 21.3431 45.9999 23V43C45.9999 44.6569 44.6568 46 42.9999 46C41.3431 46 39.9999 44.6569 39.9999 43V23C39.9999 21.3431 41.3431 20 42.9999 20Z"
        fill="#4DBAC0"
      />
      <path
        d="M42.9999 59C45.2091 59 46.9999 57.2091 46.9999 55C46.9999 52.7909 45.2091 51 42.9999 51C40.7908 51 38.9999 52.7909 38.9999 55C38.9999 57.2091 40.7908 59 42.9999 59Z"
        fill="#4DBAC0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.1776 8.90572C32.4652 3.45971 37.0073 0 42.9999 0C48.9925 0 53.5346 3.45971 57.8222 8.90572C62.039 14.2618 66.5077 22.1849 72.2251 32.3221L73.967 35.4107C78.6935 43.7906 82.4265 50.4091 84.3873 55.7215C86.3911 61.1504 86.8671 66.0797 83.8359 70.4277C80.8944 74.647 75.9866 76.3735 69.7966 77.1883C63.6303 78.0001 55.3379 78 44.6993 78H41.3007C30.6621 78 22.3696 78.0001 16.2033 77.1883C10.0132 76.3735 5.10546 74.647 2.16396 70.4277C-0.867283 66.0797 -0.391267 61.1504 1.61254 55.7215C3.57335 50.4091 7.30633 43.7906 12.0327 35.4108L13.7745 32.3227C19.492 22.1852 23.9607 14.2619 28.1776 8.90572ZM32.8919 12.6173C28.9958 17.5659 24.7482 25.0795 18.8598 35.5198L17.4052 38.0989C12.5003 46.7954 9.01472 52.9946 7.24136 57.7991C5.4908 62.5419 5.73351 65.0564 7.08593 66.9964C8.52808 69.065 11.2349 70.4825 16.9864 71.2397C22.7143 71.9937 30.6104 72 41.5453 72H44.4546C55.3894 72 63.2855 71.9937 69.0135 71.2397C74.7649 70.4825 77.4717 69.065 78.9139 66.9964C80.2663 65.0564 80.509 62.5419 78.7585 57.7991C76.9851 52.9946 73.4995 46.7953 68.5946 38.0988L67.14 35.5197C61.2516 25.0795 57.004 17.5659 53.1079 12.6173C49.2481 7.71462 46.2503 6 42.9999 6C39.7496 6 36.7517 7.71462 32.8919 12.6173Z"
        fill="#4DBAC0"
      />
    </svg>
  );
};

export default IconDeletePopup;
