import React, { useState } from 'react';
import { useLocale, useTranslations } from 'next-intl';
import VerifyCodeWrap from '../Wrap';
import { useRequest } from 'ahooks';
import { postUserPublicWebCaptchaLogin } from '@/lib/services/wangzhanjiekou';
import { useAuthProvider } from '@/lib/context';
import { formatPhoneNumber1112 } from '@/components/logreg/utils';

// const COUNT_DOWN_LIMIT = 30;

const VerifyCodeLogin = ({
  email,
  // setContent,
  goBack,
}: {
  email?: string;
  token?: string;
  setContent?: () => void;
  [k: string]: any;
}) => {
  const t = useTranslations('Registration');

  const [codes, setResult] = useState<string[]>([]);
  const { handleLogin } = useAuthProvider();

  // 验证码登录(web)
  const { loading, run: loginByVerifyCode } = useRequest(postUserPublicWebCaptchaLogin, {
    manual: true,
    onSuccess(res) {
      if (res?.code === 0) {
        if (res.data.token) {
          handleLogin?.(res.data?.token);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      }
    },
  });

  const handleSubmit = () => {
    const code = codes.join('');
    console.log('Submitted code:', code);

    loginByVerifyCode({
      username: email as string, // ?.replace(/\D/g, '') as string,
      captcha: code,
    });
  };

  const textMap = {
    ru: `Мы отправили вам на номер ${formatPhoneNumber1112(email || '')}<br/> код для подтверждения пароля,<br/>  введите его в поле ниже`,
    kk: `${formatPhoneNumber1112(email || '')} нөміріңізге жіберілген растау кодын төмендегі өріске енгізіңіз.`,
    en: `We have sent a code to the number ${formatPhoneNumber1112(email || '')}<br/> to confirm the password reset. <br/>Please enter it in the field below.`,
  };

  const currentLocal = useLocale();

  return (
    <div className={`mx-auto h-fit !w-[428px] rounded-3xl bg-white p-6 shadow-lg`}>
      <h2 className="mb-4 text-center text-lg font-semibold">{t('verifycode')}</h2>

      <div
        className="mb-4 text-center text-sm text-[#80878E]"
        dangerouslySetInnerHTML={{ __html: textMap[currentLocal as 'en' | 'kk' | 'ru'] }}
      ></div>

      <VerifyCodeWrap
        phoneNumber={email || ''}
        handleSubmit={handleSubmit}
        setResult={setResult}
        goBack={goBack}
        loading={loading}
      />
    </div>
  );
};

export default VerifyCodeLogin;
