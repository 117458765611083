import React, { useState } from 'react';
import { useTranslations } from 'next-intl';
import CustomModal from '@/components/commons/CustomModal';
import { useAuthProvider, useCartProvider } from '@/lib/context';
import IconDeletePopup from '../icons/IconDeletePopup';

const DeleteItemButton = ({
  item,
  TriggerIcon,
  removeItemFromCloud,
}: {
  item: any;
  TriggerIcon?: any;
  removeItemFromCloud: any;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const t = useTranslations('Cart');

  const { deleteShoppingCartItem } = useCartProvider(); // 删除 和 清空
  const { isLogin } = useAuthProvider();

  return (
    <CustomModal
      triggerIcon={TriggerIcon || <button className="text-[#FF3141]"> {t('delete')}</button>}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      width="w-fit"
    >
      <div className="flex h-[348px] w-[428px] flex-col items-center justify-center rounded-3xl bg-white p-6 max-md:w-[360px]">
        <div className="mb-2 flex h-[156px] w-full flex-col items-center justify-center">
          <IconDeletePopup />
          <div className={`text-blacl mt-2 text-center text-base font-semibold`}> {t('askBeforeDelete')}</div>
        </div>
        <div className="w-full">
          <button
            // disabled={loadingDelete}
            onClick={() => {
              console.log({ item });
              deleteShoppingCartItem({ ids: [item.id] });
              if (isLogin) {
                removeItemFromCloud({ ids: item.id });
              }

              setIsOpen(false);
            }}
            className="mb-6 flex h-12 w-full flex-row items-center justify-center rounded-xl bg-[#FFE6E8] text-base font-medium text-[#FF3141] transition duration-150 ease-in-out hover:opacity-90"
          >
            {t('delete')}
          </button>

          <button
            onClick={async () => {
              setIsOpen(false);
            }}
            className="flex h-12 w-full flex-row items-center justify-center rounded-xl bg-[#cce8e9] text-base font-medium text-[#4DBAC0] transition duration-150 ease-in-out hover:opacity-90"
          >
            {t('cancel')}
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default DeleteItemButton;
