import { useState } from 'react';
import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

const ColorListBox = ({ colorList }: { colorList: string[] }) => {
  const [selectedColorIndex, setSelectedColorIndex] = useState<number>(0);

  return (
    <div className="box-border flex w-[730px] flex-col items-center justify-start rounded-3xl bg-white p-8 max-md:w-full max-md:rounded-none">
      <div className="mb-6 flex h-[584px] w-[393px] flex-row items-center justify-center">
        <Image
          src={colorList[selectedColorIndex]}
          width={393}
          height={584}
          className="h-auto max-h-[584px] w-[393px]"
          alt="Image"
        />
      </div>
      <div className="flex w-full flex-row items-center justify-between">
        <Image
          src={'/images/product/left.png'}
          width={44}
          height={44}
          className={`size-11 ${selectedColorIndex <= 0 ? 'cursor-not-allowed' : 'cursor-pointer'} swiper-button-prev`}
          alt="leftImage"
          onClick={() => {
            if (selectedColorIndex <= 0) {
              return;
            }
            setSelectedColorIndex(selectedColorIndex - 1);
          }}
        />
        <div
          style={{ width: 'calc(100% - 44px - 44px)' }}
          className="flex flex-1 flex-row items-center justify-center px-[22px]"
        >
          {colorList?.length > 5 ? (
            <Swiper
              modules={[Pagination, Navigation]}
              spaceBetween={12}
              slidesPerView={5}
              normalizeSlideIndex={false} //标准化的Slide索引，这个参数可以使你的活动块（active slide）指示为最左边的那个slide（当没开启 centeredSlides 时）。
              centeredSlidesBounds={true} //居中幻灯片无缺口。当你通过设置 centeredSlides: true 使得幻灯片居中后，还可以配合设置此参数，使得第一个和最后一个slide 始终贴合边缘而不会出现缺口。此选项不适用于 loop 模式并会使 pagination 出现异常。
              centerInsufficientSlides={true} //如果开启这个参数，当slides 的总数小于 slidesPerView 时，slides居中。 不适用于 loop 模式和 grid.rows 。
              centeredSlides={true} //居中幻灯片。设定为true时，当前的active slide 会居中，而不是默认状态下的居左。
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                disabledClass: 'swiper-button-disabled',
              }}
            >
              {(colorList || []).map((item, index) => {
                return (
                  <SwiperSlide
                    style={{ width: '96px !important', height: '96px !important' }}
                    key={index}
                    className={`size-24 rounded-md`}
                  >
                    <div
                      className={`relative size-24 cursor-pointer rounded-md`}
                      onClick={() => setSelectedColorIndex(index)}
                    >
                      <Image
                        src={item}
                        width={96}
                        height={96}
                        className="size-24 rounded-md object-cover"
                        alt="Image"
                      />
                      {index === selectedColorIndex ? (
                        <div
                          style={{
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                          }}
                          className="absolute h-full w-full rounded-md border-4 border-solid border-[#4DBAC0]"
                        ></div>
                      ) : (
                        ''
                      )}
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            <>
              {(colorList || []).map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`size-24 ${index === 0 ? 'ml-0' : 'ml-3'} relative cursor-pointer rounded-md`}
                    onClick={() => setSelectedColorIndex(index)}
                  >
                    <Image src={item} width={96} height={96} className="size-24 rounded-md object-cover" alt="Image" />
                    {index === selectedColorIndex ? (
                      <div
                        style={{
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                        className="absolute h-full w-full rounded-md border-4 border-solid border-[#4DBAC0]"
                      ></div>
                    ) : (
                      ''
                    )}
                  </div>
                );
              })}
            </>
          )}
        </div>
        <Image
          src={'/images/product/right.png'}
          width={44}
          height={44}
          className={`swiper-button-nex size-11 ${selectedColorIndex >= colorList.length - 1 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          alt="rightImage"
          onClick={() => {
            if (selectedColorIndex >= colorList.length - 1) {
              return;
            }
            setSelectedColorIndex(selectedColorIndex + 1);
          }}
        />
      </div>
    </div>
  );
};

export default ColorListBox;
