/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { NewLoading } from '@/components/commons';
import PolicyTermsCommonComp from '@/components/orderprocess/PaymentMethod/PolicyTerms/PolicyTermsCommonComp';
import { useLocale } from 'next-intl';

// 协议id  类型: REFUND_POLICY  退换货政策 USER_PROTOCOL 用户注册协议  PRIVACY_POLICY 隐私政策 PUBLIC_OFFER 公开要约
export const protocolMap = {
  'User Protocol': 12,
  'Privacy policy': 13,
  'Refund policy': 14,
  'Public offer': 15,
  'OTP policy': 19,
};
export const getCompleteBaseUrl = function () {
  if (process.env.NODE_ENV === 'development') {
    return process.env.NEXT_PUBLIC_WEBSITE;
  } else {
    const protocol = window.location.protocol;
    const domain = document.domain;
    return protocol + '//' + domain;
  }
};

const PolicyAndTerm = (props: any) => {
  const { termsChecked, setTermsChecked, close, loading, contentType, setTermContent } = props;

  const [isTermsOfUseChecked, setTermsOfUseChecked] = useState(termsChecked || false);
  const [isPrivacyPolicyChecked, setPrivacyPolicyChecked] = useState(termsChecked || false);

  const currentLocale = useLocale();

  useEffect(() => {
    if (isTermsOfUseChecked && isPrivacyPolicyChecked) {
      setTermsChecked();
    }
  }, [isPrivacyPolicyChecked, isTermsOfUseChecked, setTermsChecked]);

  return (
    <>
      {loading ? (
        <div className="flex h-[80vh] w-[80vw] flex-col rounded-3xl bg-white pt-10 shadow-lg">
          <NewLoading />
        </div>
      ) : (
        <div>
          {contentType === 'TermsOfUse' && (
            <PolicyTermsCommonComp
              // articleContent={articleUse?.data?.content || '  '} // 不可以是空字符串
              url={
                // process.env.NEXT_PUBLIC_WEBSITE +
                getCompleteBaseUrl() +
                '/' +
                currentLocale +
                `/article_detail?id=${protocolMap['User Protocol']}&language=${currentLocale.toLocaleUpperCase()}`
              }
              termsChecked={termsChecked}
              close={() => {
                if (!isPrivacyPolicyChecked) {
                  setTermContent('PrivacyPolicy');
                } else {
                  close();
                }
              }}
              setContentNext={() => setTermContent('PrivacyPolicy')}
              setTermsChecked={setTermsOfUseChecked}
            />
          )}
          {contentType === 'PrivacyPolicy' && (
            <PolicyTermsCommonComp
              url={
                getCompleteBaseUrl() +
                '/' +
                currentLocale +
                `/article_detail?id=${protocolMap['Privacy policy']}&language=${currentLocale.toLocaleUpperCase()}`
              }
              termsChecked={termsChecked}
              setTermsChecked={setPrivacyPolicyChecked}
              close={() => {
                if (!isTermsOfUseChecked) {
                  setTermContent('TermsOfUse');
                } else {
                  close();
                }
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default PolicyAndTerm;
