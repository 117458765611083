import { useState, useEffect, useCallback } from 'react';
import { cloneDeep } from 'lodash';
import { CART_QUANTITY_LIMIT } from '@/shared/constants';
import EM from '@/shared/utils/EM';
import { useLocalStorageState } from 'ahooks';

export interface ShoppingCartItem extends API.WebShoppingCartItemVo {
  selected?: boolean;
  canOrder?: boolean;
  newprice?: string;
}

const useShoppingCartHook = (
  cacheShoppingCartData: (API.WebShoppingCartItemVo & { canOrder: boolean })[] | undefined,
) => {
  const [items, setItems] = useState<ShoppingCartItem[]>([]);

  const [, setIsCartEmpty] = useLocalStorageState<boolean>(`cart_empty`, {
    defaultValue: false,
    listenStorageChange: true,
  });

  const updateItems = useCallback((cacheData: API.WebShoppingCartItemVo[] | undefined) => {
    if (cacheData) {
      console.log(cacheData);
      const cloned = cloneDeep(
        cacheData.map((ele) => {
          const newEle: ShoppingCartItem = { ...ele, canOrder: true } as any;
          return newEle;
        }),
      );
      setItems(cloned);
    }
  }, []);

  useEffect(() => {
    console.log(cacheShoppingCartData);
    updateItems(
      cacheShoppingCartData?.map((ele) => {
        if (ele.quantity > CART_QUANTITY_LIMIT) {
          ele.quantity = CART_QUANTITY_LIMIT;
        }
        return ele;
      }),
    );
  }, [cacheShoppingCartData, updateItems]);

  useEffect(() => {
    if (items.length === 0) {
      EM.emit('cart_empty', true);
      setIsCartEmpty(true);
    } else {
      EM.emit('cart_empty', false);
      setIsCartEmpty(false);
    }
  }, [items, setIsCartEmpty]);

  return [items, setItems] as const;
};

export default useShoppingCartHook;
