'use client';
import React, { useEffect, useRef, useState } from 'react';
import { ShoppingCart } from 'lucide-react';
import { getUuid } from '@/shared/utils/tools';
const uuid = getUuid();

const AddToCartAnimation = ({ Icon, animate }: any) => {
  const animationStartPoint = useRef<HTMLDivElement>(null);

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  useEffect(() => {
    if (animate) {
      // 使用 requestAnimationFrame 确保DOM更新完成后再获取位置
      requestAnimationFrame(() => {
        const shoppingCartCountIcon = document.getElementById('shoppingCartCountIcon');
        const endPoint = shoppingCartCountIcon?.getBoundingClientRect();
        const startPoint = animationStartPoint?.current?.getBoundingClientRect();

        if (endPoint && startPoint?.left) {
          // 为什么每次点击动画按钮，startAnimation都会执行两次，第一次startPoint?.left和 startPoint?.top是准确的，第二次都是0.
          // alert(
          //   JSON.stringify({
          //     left: startPoint?.left,
          //     top: startPoint?.top,
          //   }),
          // );
          // return;
          const newX = endPoint.left - startPoint.left;
          const newY = endPoint.top - startPoint.top;
          setX(newX);
          setY(newY);
        }
      });
    }
  }, [animate]);

  return (
    <div className="absolute right-2 top-2 cursor-pointer text-red-500">
      <div ref={animationStartPoint} id={`animationStartPoint-${uuid}`}>
        {Icon}
      </div>

      {animate && (
        <ShoppingCart
          className="absolute"
          fill="currentColor"
          style={{
            left: '0px',
            bottom: '0px',
            animation: 'clickHeart 1.2s ease-out',
          }}
          size={16}
        />
      )}

      <style jsx>{`
        @keyframes clickHeart {
          0% {
            transform: translate(0, 0);
          }

          100% {
            transform: translate(${x}px, ${y}px);
          }
        }
      `}</style>
    </div>
  );
};

export default AddToCartAnimation;
