import React from 'react';
import { useTranslations } from 'next-intl';
import Icon1 from './icons/Icon1';
import Icon2 from './icons/Icon2';
import Icon3 from './icons/Icon3';
import Icon4 from './icons/Icon4';
import Icon5 from './icons/Icon5';
import Camera from './icons/Camera';
import { ScrollArea } from '@/components/ui/scroll-area';
import HeaderBackH5 from '../../H5/common/HeaderBackH5';

const PhotoUploadRules = ({ setRuleChecked, currentCropIndex, goBack }: any) => {
  const t = useTranslations('ImageCropRule');

  const rules = [
    {
      icon: <Icon1 />,
      title: t('t1'),
      items: [t('c11'), t('c12')],
    },
    {
      icon: <Icon2 />,
      title: t('t2'),
      items: [t('c21'), t('c22')],
    },
    {
      icon: <Icon3 />,
      title: t('t3'),
      items: [t('c31'), t('c32')],
    },
    {
      icon: <Icon4 />,
      title: t('t4'),
      items: [t('c41'), t('c42')],
    },
    {
      icon: <Icon5 />,
      title: t('t5'),
      items: [t('c51')],
    },
  ];

  return (
    <div className="rounded-xl bg-white p-6 shadow-lg max-md:h-[100vh] max-md:rounded-none max-md:p-0 max-md:pt-10">
      <div className="md:hidden">
        <HeaderBackH5 goBack={goBack} />
      </div>
      <ScrollArea style={{ height: '80vh' }} className="w-[800px] max-md:w-full">
        <div className="p-6">
          <h2 className="mb-4 text-center text-xl font-bold">{t('title')}</h2>
          <div className="mb-4 flex justify-center">
            <Camera />
          </div>
          <p className="mb-6 text-center text-gray-600">{t('desc')}</p>

          {rules.map((rule, index) => (
            <div key={index} className="mb-4 rounded-xl bg-[#F5F6F6] p-4">
              <div className="mb-2 flex items-center">
                {rule.icon}
                <h3 className="ml-2 font-semibold">{rule.title}</h3>
              </div>
              <ul className="list-disc pl-8">
                {rule.items.map((item, itemIndex) => (
                  <li key={itemIndex} className="text-sm text-gray-600">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}

          <button
            onClick={() => setRuleChecked(currentCropIndex)}
            className="mt-4 w-full rounded-xl bg-[#4DBAC0] py-2 text-black transition-colors hover:bg-teal-600"
          >
            {t('btn')}
          </button>
        </div>
      </ScrollArea>
    </div>
  );
};

export default PhotoUploadRules;
