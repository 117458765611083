const DelIcon = ({
  width = '17',
  height = '18',
  color = '#4DBAC0',
}: {
  width?: string;
  height?: string;
  color?: string;
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.33336 1.29167C7.51795 1.29167 6.82258 1.81253 6.56489 2.54162C6.44986 2.86706 6.09278 3.03764 5.76733 2.92261C5.44189 2.80758 5.27131 2.45051 5.38634 2.12506C5.81504 0.91216 6.97174 0.0416718 8.33336 0.0416718C9.69498 0.0416718 10.8517 0.91216 11.2804 2.12506C11.3954 2.45051 11.2248 2.80758 10.8994 2.92261C10.5739 3.03764 10.2169 2.86706 10.1018 2.54162C9.84413 1.81253 9.14877 1.29167 8.33336 1.29167Z"
        fill={color}
      />
      <path
        d="M0.625 4.00001C0.625 3.65483 0.904822 3.37501 1.25 3.37501H15.4167C15.7619 3.37501 16.0417 3.65483 16.0417 4.00001C16.0417 4.34518 15.7619 4.62501 15.4167 4.62501H1.25C0.904822 4.62501 0.625 4.34518 0.625 4.00001Z"
        fill={color}
      />
      <path
        d="M3.26257 6.04176C3.23961 5.69735 2.94179 5.43676 2.59738 5.45972C2.25297 5.48268 1.99238 5.7805 2.01534 6.12491L2.40155 11.918C2.47279 12.987 2.53034 13.8504 2.66532 14.528C2.80565 15.2324 3.04434 15.8208 3.53733 16.282C4.03033 16.7432 4.63328 16.9423 5.34549 17.0354C6.03053 17.125 6.89589 17.125 7.96722 17.125H8.69958C9.7709 17.125 10.6363 17.125 11.3213 17.0354C12.0335 16.9423 12.6365 16.7432 13.1295 16.282C13.6225 15.8208 13.8611 15.2324 14.0015 14.528C14.1365 13.8504 14.194 12.987 14.2653 11.918L14.6515 6.12491C14.6744 5.7805 14.4138 5.48268 14.0694 5.45972C13.725 5.43676 13.4272 5.69735 13.4042 6.04176L13.0209 11.791C12.9461 12.9142 12.8927 13.6958 12.7756 14.2838C12.6619 14.8541 12.5033 15.1561 12.2755 15.3692C12.0476 15.5824 11.7358 15.7206 11.1592 15.796C10.5647 15.8738 9.78133 15.875 8.65563 15.875H8.01116C6.88546 15.875 6.10212 15.8738 5.50762 15.796C4.93095 15.7206 4.61916 15.5824 4.39131 15.3692C4.16347 15.1561 4.00486 14.8541 3.89123 14.2838C3.77409 13.6958 3.72074 12.9142 3.64585 11.791L3.26257 6.04176Z"
        fill={color}
      />
      <path
        d="M6.18788 7.54477C6.53135 7.51043 6.83762 7.76102 6.87197 8.10448L7.28864 12.2711C7.32298 12.6146 7.07239 12.9209 6.72893 12.9552C6.38546 12.9896 6.07919 12.739 6.04484 12.3955L5.62817 8.22886C5.59383 7.8854 5.84442 7.57912 6.18788 7.54477Z"
        fill={color}
      />
      <path
        d="M11.0386 8.22886C11.073 7.8854 10.8224 7.57912 10.4789 7.54477C10.1355 7.51043 9.82919 7.76102 9.79484 8.10448L9.37817 12.2711C9.34383 12.6146 9.59442 12.9209 9.93788 12.9552C10.2813 12.9896 10.5876 12.739 10.622 12.3955L11.0386 8.22886Z"
        fill={color}
      />
    </svg>
  );
};

export default DelIcon;
