import { cn } from '@/shared/utils/shadcn';

export default function CustomCheckbox({
  checked,
  onChange,
  label,
  className,
}: {
  checked: boolean;
  onChange: (e?: any) => void;
  label: string;
  className?: string;
}) {
  return (
    <label className={cn('mr-2 flex cursor-pointer select-none items-center outline-none', className)}>
      <div className="relative">
        <input
          type="checkbox"
          className="hidden focus:outline-none active:outline-none"
          checked={checked}
          onChange={onChange}
        />
        <div
          className={`h-[18px] w-[18px] rounded-full border-2 ${checked ? 'border-teal-500 bg-[#4dbac0]' : 'border-[#9BA1A8]'}`}
        >
          {checked && (
            <svg
              className="absolute left-1/2 top-1/2 h-3 w-3 -translate-x-1/2 -translate-y-1/2 transform text-white"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
            </svg>
          )}
        </div>
      </div>
      {label && <span className="ml-2 select-none text-sm outline-none">{label}</span>}
    </label>
  );
}
