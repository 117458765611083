'use client';

import * as DialogPrimitive from '@radix-ui/react-dialog';
import Image from 'next/image';
import { useEffect } from 'react';
import useMobileDetect from '@/lib/hooks/useMobileDetect';
import { cn } from '@/shared/utils/shadcn';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { Inter } from 'next/font/google';
const inter = Inter({ subsets: ['latin'] });

const CustomModal = ({
  children: Content,
  triggerIcon: TriggerIcon,
  isOpen = false,
  setIsOpen,
  callback,
  width = 'w-fit',
  classNmases = {},
}: {
  children?: any;
  triggerIcon?: any;
  closeIcon?: any;
  isOpen?: any;
  setIsOpen: any;
  width?: 'w-fit' | 'w-full';
  callback?: () => void;
  classNmases?: any;
}) => {
  const { contentClassName } = classNmases;
  const { isMobile } = useMobileDetect();

  // Lock body scroll when modal is open
  useEffect(() => {
    if (isOpen) {
      const scrollY = window.scrollY;
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      document.body.style.top = `-${scrollY}px`;
    } else {
      const scrollY = document.body.style.top;
      if (!scrollY) return;
      document.body.style.position = '';
      document.body.style.width = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }, [isOpen]);

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    if (callback) {
      callback(); // 关闭弹层的回调
    }
  };

  const handleBackgroundClick = (e: any) => {
    if (e.target === e.currentTarget) {
      console.log(isMobile);

      if (isMobile) {
        closeModal();
      }
    }
  };

  const handleContentClick = (e: any) => {
    e.stopPropagation();
  };

  return (
    <DialogPrimitive.Root open={isOpen} onOpenChange={setIsOpen}>
      {TriggerIcon ? (
        <DialogPrimitive.Trigger asChild>
          <div onClick={openModal} className={`${width} cursor-pointer`}>
            {TriggerIcon}
          </div>
        </DialogPrimitive.Trigger>
      ) : (
        <DialogPrimitive.Trigger asChild>
          <button onClick={openModal} className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600">
            Open Modal
          </button>
        </DialogPrimitive.Trigger>
      )}

      <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay
          onClick={handleBackgroundClick}
          className="fixed inset-0 z-50 bg-black bg-opacity-30"
        />
        <DialogPrimitive.Content
          onClick={handleContentClick}
          className={cn('fixed inset-0 z-50 flex items-center justify-center', contentClassName)}
        >
          <div className="relative rounded-lg p-14">
            <VisuallyHidden>
              <DialogPrimitive.Title>{'title'}</DialogPrimitive.Title>
            </VisuallyHidden>
            <DialogPrimitive.Close
              className={`absolute right-2 top-14 cursor-pointer ${isMobile ? 'hidden' : ''}`}
              onClick={closeModal}
            >
              <Image src={'/images/search/delete.png'} className="size-9" alt="deleteImage" width={44} height={44} />
            </DialogPrimitive.Close>
            <div className={cn(inter.className)}>{Content}</div>
          </div>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
};

export default CustomModal;
