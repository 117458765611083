export const USER_LOGIN_TOKEN = 'user-login-token';
export const ANONYMOUS_USER_ID = 'anonymous-user-token';
export const CART_PAGE_SYNCH_TIME = 'cart-page-synch-time';
export const SEARCH_KEYWORDS_FOR_HEADER_INPUT = 'search_keywords_for_header_input';
export const SEARCH_PAGE_COVER_IMAGE = 'search_page_cover_image';
export const PAYMENT_PROCESS_ORDERINFO = 'payment_process_orderInfo';
export const THEME_DARK_MODE = 'theme_dark_mode';
export const CONFIRM_AGE_MODAL_TIME = 'confirm_age_modal_time'; //上次弹未成年提示弹窗的时间
export const CART_QUANTITY_LIMIT = 4;
export const APP_VERSION = '1.1.1+20250212'; //当前大版本
export const SEARCH_PAGE_KEYWORDS_AND_CATEGORY_LIST = 'search_page_keywords_and_category_list';
export const INVITATION_CODE = 'invitation_code';
