import { useCallback, useRef } from 'react';
import { Timeout } from 'ahooks/lib/useRequest/src/types';
import { useAuthProvider, useCartProvider } from '@/lib/context';
import { useRequest } from 'ahooks';
import { postProductWebShoppingCartRemove, putProductWebShoppingCartNumUpdate } from '@/lib/services/gouwuche';

/**
 * Custom hook for debounced cart updates
 * @param debounceTime Debounce time in milliseconds (default: 1500)
 */
const useDebounceCartUpdate = (debounceTime = 1500) => {
  const timerRef = useRef<Timeout | null>(null);
  const { handleUpdateProductsToShoppingCart } = useCartProvider();
  const { isLogin } = useAuthProvider();
  const { deleteShoppingCartItem } = useCartProvider(); // 删除 和 清空

  // Update item quantity in cloud
  const { run: upDateItemToCloud } = useRequest(putProductWebShoppingCartNumUpdate, {
    manual: true,
  });

  // 移除某个商品
  const { run: removeItemFromCloud } = useRequest(postProductWebShoppingCartRemove, {
    manual: true,
  });

  const debouncedUpdateCart = useCallback(
    (updatedItems: any[], item: any) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        handleUpdateProductsToShoppingCart(updatedItems);
        if (isLogin) {
          upDateItemToCloud({ id: item.id, quantity: item.quantity });
        }

        timerRef.current = null;
      }, debounceTime);
    },
    [handleUpdateProductsToShoppingCart, isLogin, upDateItemToCloud, debounceTime],
  );

  const deleteItemFromCart = useCallback(
    (item: any) => {
      deleteShoppingCartItem({ ids: [item.id] });
      if (isLogin) {
        removeItemFromCloud({ ids: item.id });
      }
    },
    [deleteShoppingCartItem, isLogin, removeItemFromCloud],
  );

  return { debouncedUpdateCart, deleteItemFromCart };
};

export default useDebounceCartUpdate;
