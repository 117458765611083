import React, { useEffect, useRef, useState } from 'react';
import { X } from 'lucide-react';
import { useTranslations } from 'next-intl';
import AlertIcon from '@/components/cart/AlertIcon';

const InputField = ({
  label,
  name,
  value,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  onMouseOut,
  error,
  isTextarea = false,
  disabled,
  errWithIcon = false,
  withClearIcon = false,
  className = '',
  labelColor = '#80878E',
  type,
  autoFocus = false,
  classNames,
  inputRef,
}: any) => {
  const { labelClass = '', placeholderClass = '' } = classNames || {};
  const InputComponent = isTextarea ? 'textarea' : 'input';
  const t = useTranslations('Profile');

  const [isFocused, setIsFocused] = useState(false);

  // 检测是否是 Edge 浏览器
  const isEdge = typeof window !== 'undefined' && /Edg/.test(window.navigator.userAgent);

  // 添加禁用 Edge 默认密码显示按钮的样式
  useEffect(() => {
    if (isEdge && type === 'password') {
      const style = document.createElement('style');
      style.innerHTML = `
         input::-ms-reveal,
         input::-ms-clear {
           display: none !important;
         }
       `;
      document.head.appendChild(style);
      return () => {
        document.head.removeChild(style);
      };
    }
  }, [isEdge, type]);

  const clearInput = () => {
    onChange?.({ target: { name, value: '' } });
  };
  const handleFocus = () => {
    setIsFocused(true);
    onFocus?.();
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsFocused(false);
    onBlur?.(e);
  };
  const handleChange = (e: any) => {
    const target = e.target;
    target.style.height = 'auto'; // 先重置高度
    target.style.height = `${Math.max(32, target.scrollHeight)}px`;
    if (target.value.trim() === '') {
      target.style.height = '32px';
    }
  };

  const textareaRef = useRef<HTMLTextAreaElement | HTMLInputElement>(null);

  useEffect(() => {
    if (value && isTextarea && textareaRef.current) {
      handleChange({ target: textareaRef.current });
    }
  }, [isTextarea, name, value]);

  const customStyles = `
    .smaller-placeholder::placeholder {
      font-size: 12px;
    }
  `;

  return (
    <div className="max-md:w-full">
      <style>{customStyles}</style>
      <div
        ref={inputRef}
        className={`relative flex cursor-pointer flex-col rounded-xl border-[1px] bg-[#F5F6F6] px-4 py-1 max-md:w-full ${
          error ? 'border-[#ff3141]' : isFocused ? 'border-[#4dbac0]' : 'border-[#cce8e9]'
        } ${className}`}
      >
        <label className={`${isTextarea ? 'mb-3' : 'mb-1'} text-sm text-[${labelColor}] ${labelClass}`}>{label}</label>
        <div className="flex w-full items-center">
          <InputComponent
            ref={textareaRef as React.Ref<HTMLInputElement & HTMLTextAreaElement>}
            type={type}
            name={name}
            value={value}
            placeholder={isFocused ? '' : placeholder || t('enterhere')}
            onChange={(e: any) => {
              onChange?.(e);
              if (isTextarea) {
                handleChange(e);
              }
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onMouseOut={onMouseOut}
            autoFocus={autoFocus}
            disabled={disabled}
            autoComplete="off"
            className={`${placeholderClass} ${isTextarea ? 'min-h-[32px]' : 'h-8'} w-full resize-none bg-transparent focus:outline-none ${
              disabled ? 'cursor-not-allowed' : ''
            }`}
            style={isTextarea ? { height: '32px' } : undefined}
          />
        </div>
        {value && withClearIcon && (
          <button
            type="button"
            onClick={clearInput}
            className="absolute right-2 top-[50%] -translate-y-[50%] rounded-full border-[1px] border-gray-400 text-gray-400 hover:text-gray-600"
          >
            <X size={16} />
          </button>
        )}
      </div>
      {error && (
        <div className="mt-1 flex items-center px-0 py-0" role="alert">
          {errWithIcon && <AlertIcon />}
          <p className="ml-1 mt-0 text-xs text-[#FF3141]">{error}</p>
        </div>
      )}
    </div>
  );
};

export default InputField;
