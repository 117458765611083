import React, { useState } from 'react';
import { useLocale, useTranslations } from 'next-intl';
import VerifyCodeWrap from '../Wrap';
import { useRequest } from 'ahooks';
import { postUserPublicWebSmsCodeVerify } from '@/lib/services/gonggongjiekou';
import { formatPhoneNumber1112 } from '@/components/logreg/utils';

// const COUNT_DOWN_LIMIT = 30;

const VerifyCodeForRegistration = ({
  phoneNumber,
  setContent,
  getResult,
  goBack,
  repData,
  thirdPlatformType,
  // thirdUserId,
}: {
  phoneNumber?: string;
  token: string;
  setContent?: () => void;
  getResult?: (v: any) => void;
  [k: string]: any;
}) => {
  const t = useTranslations('Registration');

  const [codes, setResult] = useState<string[]>([]);
  const [submitError, setSubmitError] = useState<boolean>(false);

  // 验证短信验证码(web)
  const { loading, run: SmsCodeVerify } = useRequest(postUserPublicWebSmsCodeVerify, {
    manual: true,
    onSuccess(res) {
      if (res?.code === 0) {
        getResult?.(res.data.ticket);
        setContent?.();
      } else {
        // message.error(res.msg);
        setSubmitError(true);
      }
    },
  });

  const handleSubmit = () => {
    const code = codes.join('');
    console.log('Submitted code:', code);

    SmsCodeVerify({
      phone: phoneNumber?.replace(/(?!^\+)\D/g, '') as string,
      smsCode: code,
      operateType: 'REGISTER_VERIFY',
      thirdPlatformType: thirdPlatformType ? thirdPlatformType : undefined,
      // thirdUserId, // 不是这里传，是在设置密码后调接口，实现绑定
    });
  };

  const textMap = {
    ru: `Мы отправили вам на номер ${formatPhoneNumber1112(phoneNumber || '')}<br/> код для подтверждения пароля,<br/>  введите его в поле ниже`,
    kk: `${formatPhoneNumber1112(phoneNumber || '')} нөміріңізге жіберілген растау кодын төмендегі өріске енгізіңіз.`,
    en: `We have sent a code to the number ${formatPhoneNumber1112(phoneNumber || '')}<br/> to confirm the password reset. <br/>Please enter it in the field below.`,
  };

  const currentLocal = useLocale();

  return (
    <div className={`mx-auto h-fit !w-[428px] rounded-3xl bg-white p-6 shadow-lg max-md:!w-fit`}>
      <h2 className="mb-4 text-center text-[16px] font-semibold">{t('verifycode')}</h2>

      <div
        className="mb-4 text-center text-sm text-[#80878E]"
        dangerouslySetInnerHTML={{ __html: textMap[currentLocal as 'en' | 'kk' | 'ru'] }}
      ></div>

      <VerifyCodeWrap
        phoneNumber={phoneNumber || ''}
        repData={repData}
        handleSubmit={handleSubmit}
        setResult={setResult}
        goBack={goBack}
        loading={loading}
        submitError={submitError}
        setSubmitError={setSubmitError}
      />
    </div>
  );
};

export default VerifyCodeForRegistration;
