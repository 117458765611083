import React, { useEffect, useMemo, useState } from 'react';
import { useRequest } from 'ahooks';
import { useLocale, useTranslations } from 'next-intl';
import message from '@/components/commons/Message/index';
import { postUserPublicWebRegister } from '@/lib/services/wangzhanjiekou';
import { validatePasswordIntel } from '../../../utils';
import AlertIcon from '@/components/cart/AlertIcon';
import { NewLoading } from '@/components/commons';
import InputField from '@/components/commons/InputField';
import EyeOff from '../../../icons/EyeOff';
import Eye from '../../../icons/Eye';
import { useAuthProvider } from '@/lib/context';
import Image from 'next/image';
import { INVITATION_CODE } from '@/shared/constants';
import storage from '@/shared/utils/storage';

const PasswordInput = ({ phoneNumber, ticket, abbrCountry, setNext, thirdUserId, thirdPlatformType, goBack }: any) => {
  const t = useTranslations('Registration');

  const { handleLogin } = useAuthProvider();

  const currentLocale = useLocale();

  // 注册
  const { loading, run: toRegister } = useRequest(postUserPublicWebRegister, {
    manual: true,
    onSuccess(res) {
      console.log(res);
      if (res.data?.token) {
        handleLogin?.(res.data?.token);
        setTimeout(() => {
          setNext();
        }, 1000);
      } else {
        message.error(res.msg || t('failed'), 2000);
      }
    },
  });

  // const validatePassword = (pass: string) => {
  //   // Basic password strength check (you can enhance this)
  //   return pass.length >= 8 && /[A-Z]/.test(pass) && /[a-z]/.test(pass) && /[0-9]/.test(pass);
  // };

  // const initRef = useRef(false);

  const [password, setPassword] = useState('');
  const [replayPassword, setReplayPassword] = useState('');

  const [isSame, setIsSame] = useState<boolean>(true);
  const [blur, manageBlur] = useState<number>(1);

  const [passwordVerifyText, setPasswordVerifyText] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const togglePasswordVisibility = (field: string) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  // Edge-specific detection
  // const isEdge = navigator.userAgent.includes('Edg');
  // console.log({ isEdge });

  const handlePassChange = (e: any) => {
    // const value = e.target.value.replace(/\D/g, '')
    const value = e.target.value.trim();
    setPassword(value);
    const validateRes = validatePasswordIntel(value);
    // console.log(validateRes);
    setPasswordVerifyText(validateRes[currentLocale as 'en' | 'ru' | 'kk']);
  };

  const handleReplayPassChange = (e: any) => {
    // const value = e.target.value.replace(/\D/g, '')
    setIsSame(false);
    const value = e.target.value;
    setReplayPassword(value);
    if (value.length >= password.length) {
      setIsSame(value === password);
    }
  };

  useEffect(() => {
    if (password && replayPassword && password === replayPassword) {
      setIsSame(true);
    }
  }, [password, replayPassword]);

  const disabled = useMemo(() => {
    console.log(isSame, loading, passwordVerifyText, blur);

    // if (!initRef.current) {
    //   return true;
    // }

    if (!password) return true;
    if (!replayPassword) return true;
    if (loading) return true;

    return !isSame || !!passwordVerifyText;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSame, loading, passwordVerifyText, blur]);

  return (
    <div className="relative inset-0 flex min-w-fit items-center justify-center bg-opacity-50">
      <div className="h-screen w-full rounded-none bg-white p-[20px] shadow-lg">
        <div className="relative w-full border-0 bg-transparent">
          <div className="absolute -top-2 left-2 z-50 cursor-pointer" onClick={goBack}>
            <Image
              width={24}
              height={24}
              src={'/images/category/leftArrows.png'}
              className="mr-4 size-6"
              alt="leftArrows"
            />
          </div>
        </div>
        <h2 className="mb-4 text-center text-2xl font-bold">{t('toRegister')}</h2>

        <div className="w-[380px] space-y-4 max-md:w-full">
          <div className="relative">
            <InputField
              label={t('password')}
              type={showPassword ? 'text' : 'password'}
              placeholder="Qwerty123@"
              labelColor="#000"
              value={password}
              onChange={handlePassChange}
              onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                const value = e.target.value;
                if (replayPassword) {
                  setIsSame(value === replayPassword);
                }
              }}
              onFocus={() => {
                // setIsSame(false);
              }}
              // autoFocus={true}
              onMouseOut={() => {
                manageBlur((v) => v + 1);
                if (password && replayPassword) {
                  setIsSame(replayPassword === password);
                }
              }}
            />

            <button
              type="button"
              onClick={() => togglePasswordVisibility('password')}
              className="absolute right-4 top-[50%] -translate-y-[50%] text-[#9ba1a8]"
            >
              {showPassword ? <Eye /> : <EyeOff />}
            </button>
          </div>

          {passwordVerifyText && (
            <div className="flex items-center px-0 py-0" role="alert">
              <AlertIcon />
              <div className="ml-1 text-[12px] text-[#ff3141]">{passwordVerifyText}</div>
            </div>
          )}

          <div className="relative">
            <InputField
              label={t('inputagain')}
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder="Qwerty123@"
              labelColor="#000"
              value={replayPassword}
              onChange={handleReplayPassChange}
              onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                const value = e.target.value;
                if (password) {
                  setIsSame(value === password);
                }
              }}
              onFocus={() => {
                // setIsSame(false);
              }}
              onMouseOut={() => {
                manageBlur((v) => v + 1);
                if (password && replayPassword) {
                  setIsSame(replayPassword === password);
                }
              }}
              onTouchEnd={() => {
                if (password && replayPassword) {
                  setIsSame(replayPassword === password);
                }
              }}
            />
            <button
              type="button"
              onClick={() => togglePasswordVisibility('confirm')}
              className="absolute right-4 top-[50%] -translate-y-[50%] text-[#9ba1a8]"
            >
              {showConfirmPassword ? <Eye /> : <EyeOff />}
            </button>
          </div>

          {!isSame && (
            <div className="flex items-center px-0 py-0" role="alert">
              <AlertIcon />
              <div className="ml-1 text-[12px] text-[#ff3141]"> {t('notsametext')}</div>
            </div>
          )}

          <button
            className={`w-full rounded-xl bg-[#4DBAC0] py-3 text-black transition duration-150 ease-in-out hover:opacity-85 ${disabled ? '!bg-[var(--primary-clor-gray3)] !text-[var(--theme-gray-1)]' : ''}`}
            disabled={disabled}
            onClick={() => {
              // console.log(ticket, abbrCountry, phoneNumber, validatePasswordIntel(password));
              // return;
              if (ticket && abbrCountry && phoneNumber && !passwordVerifyText && password === replayPassword) {
                toRegister({
                  mobile: phoneNumber.replace(/(?!^\+)\D/g, ''), // 注册保留开头的“+”，如果有的话。
                  password,
                  replayPassword,
                  // name: NamePlaceHolers[router.locale as 'en' | 'ru' | 'kk'] || 'Umay User',
                  name: '-',
                  ticket: ticket, // 验证短信验证码通过后返回的临时ticket
                  country: abbrCountry,
                  thirdUserId,
                  thirdPlatformType: thirdUserId ? (thirdPlatformType ? thirdPlatformType : 'google') : undefined,
                  invitationCode: storage.get(INVITATION_CODE) || undefined,
                } as any);
              }
            }}
          >
            {loading ? <NewLoading type="special" /> : t('enter')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordInput;
