// @ts-ignore
/* eslint-disable */
import request from '@/shared/utils/axios';

/** 获取图像验证码 GET /admin/public/verify-code */
export async function getAdminPublicVerifyCode(options?: { [key: string]: any }) {
  return request<{ code: number; msg: string; data: { clientCode: string; verifyCode: string } }>(
    '/admin/public/verify-code',
    {
      method: 'GET',
      ...(options || {}),
    },
  );
}

/** google识别图片信息 GET /kaz/google/product/detail/image */
export async function getKazGoogleProductDetailImage(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getKazGoogleProductDetailImageParams,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/kaz/google/product/detail/image', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** tesst4j识别图片信息 只返回了识别到的图片中的汉字，去除了字母以及其他字符 GET /ocr/kaz/product/detail/image */
export async function getOcrKazProductDetailImage(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOcrKazProductDetailImageParams,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/ocr/kaz/product/detail/image', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 取得银行支付配置信息 GET /order/public/bank/pay/conf */
export async function getOrderPublicBankPayConf(options?: { [key: string]: any }) {
  return request<{ code: number; msg: string; data: API.bankInfo[] }>('/order/public/bank/pay/conf', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 取得购物限制 GET /order/public/buy/limit */
export async function getOrderPublicBuyLimit(options?: { [key: string]: any }) {
  return request<{ code: number; msg: string; data: { maxOrderMoney: number; maxSingleSkuNum: string } }>(
    '/order/public/buy/limit',
    {
      method: 'GET',
      ...(options || {}),
    },
  );
}

/** 获取哈萨克斯坦城市列表 用于用户添加收货地址时一级省份选择,该数据用于用户收货地址中的省份信息: provinceCode  provinceName GET /order/public/city/list */
export async function getOrderPublicCityList(options?: { [key: string]: any }) {
  return request<{
    code: number;
    msg: string;
    data: {
      cityCode: string;
      cityName: string;
      longitude: string;
      latitude: string;
      regionId: string;
      postCode: string;
      freight?: number;
    }[];
  }>('/order/public/city/list', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取中包裹打包限制条件 GET /order/public/mid/package/limit */
export async function getOrderPublicMidPackageLimit(options?: { [key: string]: any }) {
  return request<{ code: number; msg: string; data: { midPackageWeightLimit: number; midPackageAmountLimit: number } }>(
    '/order/public/mid/package/limit',
    {
      method: 'GET',
      ...(options || {}),
    },
  );
}

/** 用户协议,退款政策,隐私协议,公开要约 GET /order/public/policy */
export async function getOrderPublicPolicy(options?: { [key: string]: any }) {
  return request<{
    code: number;
    msg: string;
    data: { userProtocol: string; privacyPolicy: string; publicOffer: string; refundPolicy: string };
  }>('/order/public/policy', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取哈萨克斯坦地区列表 用于用户收货地址第二级城市信息 cityCode cityName,
用户收货地下第三级areaCode,areaName不填 GET /order/public/region/list */
export async function getOrderPublicRegionList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOrderPublicRegionListParams,
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: { regionCode: string; regionName: string }[] }>(
    '/order/public/region/list',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 获取支持的送货方式 GET /order/public/shop/delivery/method */
export async function getOrderPublicShopDeliveryMethod(options?: { [key: string]: any }) {
  return request<{ code: number; msg: string; data: string[] }>('/order/public/shop/delivery/method', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取支持的支付方式 GET /order/public/shop/payment/method */
export async function getOrderPublicShopPaymentMethod(options?: { [key: string]: any }) {
  return request<{
    code: number;
    msg: string;
    data: {
      name: string;
      payMethod: string;
      enable: string;
      paymentType: string;
      creditType: string;
      bankName: string;
    }[];
  }>('/order/public/shop/payment/method', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 取得多语言OTP 协议详情(APP) 返回该文章具体某语言的信息 GET /product/public/app/article/lang */
export async function getProductPublicAppArticleLang(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductPublicAppArticleLangParams,
  options?: { [key: string]: any },
) {
  return request<{
    code: number;
    msg: string;
    data: {
      title: string;
      titleImage: string;
      content: string;
      abstract: string;
      groupName: string;
      publisher: string;
      publishAt: string;
    };
  }>('/product/public/app/article/lang', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 某语言文章详情 返回该文章具体某语言的信息 GET /product/public/article/lang */
export async function getProductPublicArticleLang(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductPublicArticleLangParams,
  options?: { [key: string]: any },
) {
  return request<{
    code: number;
    msg: string;
    data: {
      title: string;
      titleImage: string;
      content: string;
      abstract: string;
      groupName: string;
      publisher: string;
      publishAt: string;
    };
  }>('/product/public/article/lang', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 文章列表 GET /product/public/article/list */
export async function getProductPublicArticleList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductPublicArticleListParams,
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: API.WebArticleDetailVo[] }>('/product/public/article/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 某分组文章列表 返回某分组下的某语言的文章信息列表 GET /product/public/articles/group/lang */
export async function getProductPublicArticlesGroupLang(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductPublicArticlesGroupLangParams,
  options?: { [key: string]: any },
) {
  return request<{
    code: number;
    msg: string;
    data: {
      id: string;
      title: string;
      abstract?: string;
      content: string;
      titleImage?: string;
      publisher: string;
      publishAt: string;
    }[];
  }>('/product/public/articles/group/lang', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 取得网站公告 GET /product/public/board */
export async function getProductPublicBoard(options?: { [key: string]: any }) {
  return request<{ code: number; msg: string; data?: { contentRU?: string; contentKK?: string; contentEN?: string } }>(
    '/product/public/board',
    {
      method: 'GET',
      ...(options || {}),
    },
  );
}

/** 取得FAQ问题列表 GET /product/public/faq/list */
export async function getProductPublicFaqList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductPublicFaqListParams,
  options?: { [key: string]: any },
) {
  return request<{
    code: number;
    msg: string;
    data: {
      category: string;
      categoryEN: string;
      categoryKZ: string;
      categoryCN: string;
      faqList: {
        id: number;
        title: string;
        titleEN: string;
        titleKZ: string;
        titleCN: string;
        content: string;
        contentEN: string;
        contentKZ: string;
        contentCN: string;
        answer: string;
        answerEN: string;
        answerKZ: string;
        answerCN: string;
        priority: number;
      }[];
    }[];
  }>('/product/public/faq/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取Feed数据 GET /product/public/feed/${param0} */
export async function getProductPublicFeedFeedId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductPublicFeedFeedIdParams,
  options?: { [key: string]: any },
) {
  const { feedId: param0, ...queryParams } = params;
  return request<{
    code: number;
    msg: string;
    data: {
      title: string;
      description: string;
      items: {
        id: string;
        title: string;
        description: string;
        imageList: string;
        availability: string;
        price: string;
        brand: string;
        condition: string;
      }[];
    };
  }>(`/product/public/feed/${param0}`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 搜索热词 GET /product/public/hot/words */
export async function getProductPublicHotWords(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductPublicHotWordsParams,
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: string[] }>('/product/public/hot/words', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 关键词提示 GET /product/public/keywords */
export async function getProductPublicKeywords(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductPublicKeywordsParams,
  options?: { [key: string]: any },
) {
  return request<{
    code: number;
    msg: string;
    data: {
      total: string;
      pageNum: string;
      pageSize: string;
      pages: string;
      list: { keywords?: string; keywordId?: string }[];
    };
  }>('/product/public/keywords', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 多语言文章详情 注意: 前期测试有可能接口返回的data是空的,注意处理一下,这种情况证明后端没有配置 GET /product/public/multiple/lang/article */
export async function getProductPublicMultipleLangArticle(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductPublicMultipleLangArticleParams,
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: { title: string; titleImage: string; content: string } }>(
    '/product/public/multiple/lang/article',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 热门分类 product-service GET /product/public/popular/category */
export async function getProductPublicPopularCategory(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductPublicPopularCategoryParams,
  options?: { [key: string]: any },
) {
  return request<{
    code: number;
    msg: string;
    data: { name: string; image: string; categoryId: number; categoryNameCN: string }[];
  }>('/product/public/popular/category', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 商品分类 product-service GET /product/public/product/category/all */
export async function getProductPublicProductCategoryAll(options?: { [key: string]: any }) {
  return request<{
    code: number;
    msg: string;
    data: {
      id: number;
      parentCateId: number;
      categoryName: string;
      categoryNameCN: string;
      categoryNameEN: string;
      categoryNameKZ: string;
      image: string;
      level: number;
      children: API.WebProductCategoryVo[];
      adult: boolean;
      saleChannel: string;
    }[];
  }>('/product/public/product/category/all', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 商品分类(只取一级) product-service GET /product/public/product/category/list */
export async function getProductPublicProductCategoryList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductPublicProductCategoryListParams,
  options?: { [key: string]: any },
) {
  return request<{
    code: number;
    msg: string;
    data: {
      id: number;
      parentCateId: number;
      categoryName: string;
      categoryNameCN: string;
      categoryNameEN: string;
      categoryNameKZ: string;
      image: string;
      level: number;
      children: API.WebProductCategoryVo[];
      adult: boolean;
      saleChannel: string;
    }[];
  }>('/product/public/product/category/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 取得商品当前销售状态 GET /product/public/product/sale/state */
export async function getProductPublicProductSaleState(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductPublicProductSaleStateParams,
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: boolean }>('/product/public/product/sale/state', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 今日汇率 product-service GET /product/public/today/exchange-rate */
export async function getProductPublicTodayExchangeRate(options?: { [key: string]: any }) {
  return request<{ code: number; msg: string; data: { rmb: number; kzt: number } }>(
    '/product/public/today/exchange-rate',
    {
      method: 'GET',
      ...(options || {}),
    },
  );
}

/** 取得图片搜索ID 该接口用于商品图片搜索, POST /product/public/upload/search/image */
export async function postProductPublicUploadSearchImage(body: {}, file?: File, options?: { [key: string]: any }) {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      if (typeof item === 'object' && !(item instanceof File)) {
        if (item instanceof Array) {
          item.forEach((f) => formData.append(ele, f || ''));
        } else {
          formData.append(ele, JSON.stringify(item));
        }
      } else {
        formData.append(ele, item);
      }
    }
  });

  return request<{ code: number; msg: string; data: string }>('/product/public/upload/search/image', {
    method: 'POST',
    data: formData,
    requestType: 'form',
    ...(options || {}),
  });
}

/** 取得多语言OTP 协议详情(WEB) 返回该文章具体某语言的信息 GET /product/public/web/article/lang */
export async function getProductPublicWebArticleLang(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductPublicWebArticleLangParams,
  options?: { [key: string]: any },
) {
  return request<{
    code: number;
    msg: string;
    data: {
      title: string;
      titleImage: string;
      content: string;
      abstract: string;
      groupName: string;
      publisher: string;
      publishAt: string;
    };
  }>('/product/public/web/article/lang', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 用户行为跟踪(客户端埋点)(APP端) 目前前端埋点有两处地方:
1.用户进入商品搜索界面,用户每输入一个搜索关键词,在提交搜索前或搜索后,调用此接口保存商品搜索关键词,如果用户未输入关键词,不用调用此接口
2.用户进入商品详情界时,提取商品详情中的searchKey字段值,按空格拆分关键词后,调用此接口保存 POST /user/app/behavior/track */
export async function postUserAppBehaviorTrack(
  body: {
    behavior: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.BaseResult>('/user/app/behavior/track', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 发送邮件验证码(app) 需要用户登录 POST /user/app/email/captcha */
export async function postUserAppEmailCaptcha(
  body: {
    email: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.BaseResult>('/user/app/email/captcha', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 发送双重邮件验证(app) POST /user/app/email/double/verify/url */
export async function postUserAppEmailDoubleVerifyUrl(options?: { [key: string]: any }) {
  return request<API.BaseResult>('/user/app/email/double/verify/url', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 发送OTP CODE(app) 4位 POST /user/app/otpcode/send */
export async function postUserAppOtpcodeSend(
  body: {
    phone: string;
    iin: string;
    ticket: string;
    clientUid: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.BaseResult>('/user/app/otpcode/send', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 验证OTPCODE(app) POST /user/app/otpcode/verify */
export async function postUserAppOtpcodeVerify(
  body: {
    phone: string;
    code: string;
  },
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: { pass: boolean } }>('/user/app/otpcode/verify', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 验证邮件验证码(APP) POST /user/public/app/email/code/verify */
export async function postUserPublicAppEmailCodeVerify(
  body: {
    email: string;
    captcha: string;
  },
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: { ticket: string } }>('/user/public/app/email/code/verify', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 验证短信验证码(app) 测试环境验证码传: 666666 POST /user/public/app/sms/code/verify */
export async function postUserPublicAppSmsCodeVerify(
  body: {
    phone: string;
    smsCode: string;
    /** ONLY_VERIFY：仅验证手机号与短信验证码有效性  
REGISTER_VERIFY：注册验证,成功会返回ticket
LOGIN_VERIFY：登录验证，登录成功会返回token */
    operateType: string;
    /** 第三方平台用户id */
    thirdUserId?: string;
    /** 第三方平台类型 */
    thirdPlatformType?: string;
  },
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: { ticket: string; token: string } }>(
    '/user/public/app/sms/code/verify',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    },
  );
}

/** 校验滑动验证 POST /user/public/captcha/check */
export async function postUserPublicCaptchaCheck(
  body: {
    pointJson: string;
    /** 获取滑动验证码时取得 */
    token: string;
    /** 固定值: blockPuzzle */
    captchaType: string;
    clientUid: string;
    ts: number;
  },
  options?: { [key: string]: any },
) {
  return request<{
    repCode: string;
    repData: { captchaType: string; pointJson: string; token: string; result: boolean; ts: number };
    success: boolean;
    ticket?: string;
  }>('/user/public/captcha/check', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取滑动验证码数据 POST /user/public/captcha/get */
export async function postUserPublicCaptchaGet(
  body: {
    /** blockPuzzle  滑块验证(我们使用这种) 
clickWord 点选文字 */
    captchaType: string;
    /** 前端检查如果没有就生成一个,这个客户取验证码时都传这个UUUID,第一次传了之后就可以不传了,当然也可以传 */
    clientUid?: string;
  },
  options?: { [key: string]: any },
) {
  return request<{
    repCode: string;
    repData: {
      originalImageBase64: string;
      point: { x: number; y: number };
      jigsawImageBase64: string;
      token: string;
      secretKey: string;
      result: boolean;
      opAdmin: boolean;
    };
    success: boolean;
    error: boolean;
  }>('/user/public/captcha/get', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 字典查询 GET /user/public/dictionaries */
export async function getUserPublicDictionaries(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getUserPublicDictionariesParams,
  options?: { [key: string]: any },
) {
  return request<{
    code: number;
    msg: string;
    data: {
      id: string;
      type: string;
      key: string;
      value: string;
      zhValue?: string;
      enValue?: string;
      ruValue?: string;
      kkValue?: string;
      name: string;
      remark: string;
      sort: string;
    }[];
  }>('/user/public/dictionaries', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 发送邮件验证码(不登录) 后端会判断邮箱是否存在 POST /user/public/email/captcha */
export async function postUserPublicEmailCaptcha(
  body: {
    email: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.BaseResult>('/user/public/email/captcha', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 检查邮箱是否存在 GET /user/public/email/exist */
export async function getUserPublicEmailExist(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getUserPublicEmailExistParams,
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: boolean }>('/user/public/email/exist', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 通用文件上传 POST /user/public/file-upload */
export async function postUserPublicFileUpload(body: {}, file?: File, options?: { [key: string]: any }) {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      if (typeof item === 'object' && !(item instanceof File)) {
        if (item instanceof Array) {
          item.forEach((f) => formData.append(ele, f || ''));
        } else {
          formData.append(ele, JSON.stringify(item));
        }
      } else {
        formData.append(ele, item);
      }
    }
  });

  return request<{ code: number; msg: string; data: string }>('/user/public/file-upload', {
    method: 'POST',
    data: formData,
    requestType: 'form',
    ...(options || {}),
  });
}

/** 忘记密码(第一步) 根据手机号发送短信验证码或者邮件验证码 POST /user/public/forget/password/one */
export async function postUserPublicForgetPasswordOne(
  body: {
    /** 手机号或邮箱恁选其一 */
    mobile?: string;
    email?: string;
    captchaVerification: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.BaseResult>('/user/public/forget/password/one', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 忘记密码 根据验证码和新密码,重置用户密码 POST /user/public/forget/password/two */
export async function postUserPublicForgetPasswordTwo(
  body: {
    /** 手机号验证时不为空 */
    mobile?: string;
    /** 邮箱验证时不为空 */
    email?: string;
    newPassword: string;
    /** 邮件验证码验证码验证成功后获取 */
    ticket: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.BaseResult>('/user/public/forget/password/two', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 检查账号是否已存在 GET /user/public/phone/exist */
export async function getUserPublicPhoneExist(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getUserPublicPhoneExistParams,
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: boolean }>('/user/public/phone/exist', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取是否开放注册 GET /user/public/register/switch */
export async function getUserPublicRegisterOpenApiSwitch(options?: { [key: string]: any }) {
  return request<{ code: number; msg: string; data: { registerSwitch: boolean } }>('/user/public/register/switch', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 发送短信验证码 POST /user/public/sms/captcha/send */
export async function postUserPublicSmsCaptchaSend(
  body: {
    /** 测试手机号: 87773300013 */
    mobile: string;
    /** /user/public/captcha/check */
    ticket: string;
    clientUid: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.BaseResult>('/user/public/sms/captcha/send', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 验证邮件验证码(WEB) 该接口也用于用户登录后修改密码 POST /user/public/web/email/code/verify */
export async function postUserPublicWebEmailCodeVerify(
  body: {
    email: string;
    captcha: string;
  },
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: { ticket: string } }>('/user/public/web/email/code/verify', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 验证短信验证码(web) 测试环境验证码传: 666666 POST /user/public/web/sms/code/verify */
export async function postUserPublicWebSmsCodeVerify(
  body: {
    phone: string;
    smsCode: string;
    /** ONLY_VERIFY 仅验证手机号与短信验证码有效性  
REGISTER_VERIFY 注册验证,成功会返回ticket
LOGIN_VERIFY：登录验证，登录成功会返回token
FORGET_PWD_VERIFY 忘记密码时验证 */
    operateType: string;
    /** 第三方平台用户id */
    thirdUserId?: string;
    /** 第三方平台类型 */
    thirdPlatformType?: string;
  },
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: { ticket: string; token: string } }>(
    '/user/public/web/sms/code/verify',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    },
  );
}

/** 用户行为跟踪(客户端埋点)(WEB端) 目前前端埋点有两处地方:
1.用户进入商品搜索界面,用户每输入一个搜索关键词,在提交搜索前或搜索后,调用此接口保存商品搜索关键词,如果用户未输入关键词,不用调用此接口
2.用户进入商品详情界时,提取商品详情中的searchKey字段值,按空格拆分关键词后,调用此接口保存 POST /user/web/behavior/track */
export async function postUserWebBehaviorTrack(
  body: {
    behavior: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.BaseResult>('/user/web/behavior/track', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 发送邮件验证码(web) 需要用户登录 POST /user/web/email/captcha */
export async function postUserWebEmailCaptcha(
  body: {
    email: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.BaseResult>('/user/web/email/captcha', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 发送双重邮件验证(web) POST /user/web/email/double/verify/url */
export async function postUserWebEmailDoubleVerifyUrl(options?: { [key: string]: any }) {
  return request<API.BaseResult>('/user/web/email/double/verify/url', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 发送OTP CODE(web) 4位 POST /user/web/otpcode/send */
export async function postUserWebOtpcodeSend(
  body: {
    phone: string;
    iin: string;
    ticket: string;
    /** 类似发送短信验证码接口调用方式传参 */
    clientUid: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.BaseResult>('/user/web/otpcode/send', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 验证OTPCODE(web) POST /user/web/otpcode/verify */
export async function postUserWebOtpcodeVerify(
  body: {
    phone: string;
    code: string;
  },
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: { pass: boolean } }>('/user/web/otpcode/verify', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
